<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row>
      <a-col span="12">
        <a-form-model-item label="温度阈值">
          <div style="display: flex;">
            <a-form-model-item prop="tempMinThreshold">
              <a-input-number
                v-model="form.tempMinThreshold"
                placeholder="请输入最小阈值"
                style="width: 150px;"
                :min="0"
              />
            </a-form-model-item>
            <span style="margin: 0 10px;">~</span>
            <a-form-model-item prop="tempMaxThreshold">
              <a-input-number
                v-model="form.tempMaxThreshold"
                placeholder="请输入最大阈值"
                style="width: 150px;"
                :min="0"
              />
            </a-form-model-item>
          </div>
        </a-form-model-item>
      </a-col>
      <a-col span="12">
        <a-form-model-item label="湿度阈值">
          <div style="display: flex;">
            <span style="margin-right: 10px">不低于</span>
            <a-form-model-item prop="humidMinThreshold">
              <a-input-number
                v-model="form.humidMinThreshold"
                placeholder="请输入湿度"
                style="width: 180px;"
                :min="0"
            /></a-form-model-item>
            <span style="margin-left: 10px">%</span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { putScrConfig, getScrConfig } from '@/services/device-manage/setting';

@Component()
export default class ScrConfig extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  editData;

  form = {
    humidMinThreshold: 95,
    tempMaxThreshold: 22,
    tempMinThreshold: 20,
  };
  rules = {
    humidMinThreshold: [
      {
        required: true,
        message: '湿度阈值不能为空',
      },
    ],
    tempMaxThreshold: [
      {
        required: true,
        message: '温度最大阈值不能为空',
      },
    ],
    tempMinThreshold: [
      {
        required: true,
        message: '温度最小阈值不能为空',
      },
    ],
  };
  mounted() {
    this.initFrom();
  }
  async initFrom() {
    try {
      const data = await getScrConfig(this.editData.pkId);
      this.form = data;
    } catch {
      return false;
    }
  }
  async getValue() {
    await this.$refs.form.validate();
    this.form.deviceId = this.editData.pkId;
    try {
      const str = '设置成功';
      await putScrConfig(this.form);
      this.$message.success(str);
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style></style>
