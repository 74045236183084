<template>
  <div :class="$style.listWrap">
    <ScreenSelect
      @changeTimeRange="selectTime"
      :showType="false"
      :placeholder="$t('safety.sensorType')"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%', y: 'max-content' }"
      @change="handlePagination"
      :transformCellText="({ text }) => (text === -1 ? '-' : text || '-')"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { getStatusData } from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class ScrDeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }

  mounted() {
    this.getTableList();
  }

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '上报时间',
        dataIndex: 'renewTime',
        customRender: txt => this.formateTime(txt),
      },
      {
        align: 'left',
        title: '温度' + '(℃)',
        dataIndex: 'temp',
      },
      {
        align: 'left',
        title: '湿度(%)',
        dataIndex: 'humid',
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    try {
      this.loading = true;
      const { records, total } = await getStatusData(params, this.deviceGroup);
      this.tableData = records;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  .normal {
    color: var(--success);
  }
  .warning {
    color: var(--warning);
  }
  .alarm {
    color: var(--delete);
  }
  .sensorDiv {
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sensor {
      height: 22px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
