import request from '../request';

const serviceName = '/smart-site/device';

/** @name 保存扬尘设备阈值设置 */
export function dustThresholdSetting(data) {
  return request(`${serviceName}/dust/config`, {
    method: 'POST',
    body: data,
  });
}

/** @name 获取扬尘设备阈值设置 */
export function getDustThreshold(deviceId) {
  return request(`${serviceName}/dust/config/${deviceId}`, {
    method: 'GET',
  });
}

/** @name 保存洗车时间阈值 */
export function carThresholdSetting(data) {
  return request(`${serviceName}/vc/saveConfig`, {
    method: 'POST',
    body: data,
  });
}

/** @name 获取洗车时间阈值 */
export function getCarThreshold(deviceId) {
  return request(`${serviceName}/vc/getWashConfig/${deviceId}`, {
    method: 'GET',
  });
}

/** @name 获取监测点配置 */
export function getPointConfig(data) {
  return request(`${serviceName}/ct/config`, {
    method: 'GET',
    body: data,
  });
}

/** @name 更新监测点配置 */
export function putPointConfig(data) {
  return request(`${serviceName}/ct/config`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 获取标养室配置 */
export function getScrConfig(deviceId) {
  return request(`${serviceName}/scr/config`, {
    method: 'GET',
    body: { deviceId },
  });
}

/** @name 更新标养室配置 */
export function putScrConfig(data) {
  return request(`${serviceName}/scr/config`, {
    method: 'PUT',
    body: data,
  });
}
