<template>
  <a-select
    :allowClear="allowClear"
    showSearch
    style="min-width: 179px;"
    :options="options"
    v-bind="$attrs"
    v-model="selectValue"
    optionFilterProp="children"
    @deselect="handleDeSelect"
    @select="handleSelect"
  ></a-select>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component()
export default class AsyncSelect extends Vue {
  @Prop({ type: Function, default: () => [] }) asyncOptions;
  @Prop({ type: [String, Number, Array], default: undefined }) value;
  @Prop({ type: Boolean, default: true }) autoFecth;
  @Prop({ type: Boolean, default: true }) allowClear;

  get selectValue() {
    return this.value;
  }

  set selectValue(val) {
    this.$emit('input', val);
    this.$emit('change', val);
  }

  options = [];

  async initOptions() {
    this.options = await this.asyncOptions();
  }

  handleDeSelect(val) {
    const item = this.options.find(({ value }) => value === val);
    this.$emit('deselect', item);
  }

  handleSelect(val) {
    const item = this.options.find(({ value }) => value === val);
    this.$emit('select', item);
  }

  created() {
    this.autoFecth && this.initOptions();
  }
}
</script>
